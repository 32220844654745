import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./massager-section.scss";
import { massagers } from "../../static";
import { useParams } from "react-router-dom";
import { CustomButton } from "../../atoms/CustomButton/CustomButton";
import ReactDOM from "react-dom";
import { Purchase} from "../Purchase/Purchase";

type Feature = {
  id: number;
  name: string;
  features: string[];
};

type Features = {
  feature_1: Feature;
  feature_2: Feature;
  feature_3: Feature;
};


interface IProps {
  id: number;
  name: string;
  title: string;
  material: string;
  massage_area: string;
  control_type: string;
  condition: string;
  description: string;
  features: Features;
  actualPrice: number;
}

export const MassagerSection: React.FC = () => {
 const {t} = useTranslation();
 const {name} = useParams<{ name: string }>();
 const [showModal, setShowModal] = useState(false);
 const [selectedMassager, setSelectedMassager] = useState<IProps | null>(null);
 const [imageSource, setImageSource] = useState<string | null>(null);
 const [activeTab, setActiveTab] = useState(0);

 const MassagerContext = React.createContext(setSelectedMassager);

 const node = document.querySelector("#portal") as HTMLDivElement;

 const closeModal = () => {
    setShowModal(false);
  };

 const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
   setActiveTab(newValue);
 };

 const handleBuyMassager = () => {
    setShowModal(true);
  };

 useEffect(() => {
   if (name) {
    const massager = massagers.find((item) => item.name === name);
    const imageSource = (() => {
    switch (massager?.id) {
        case 1:
          return "/massagers/classic/wood";
        case 2:
          return "/massagers/classic/plastic";
        case 3:
          return "/massagers/mount/wood";
        case 4:
          return "/massagers/mount/plastic";
        case 5:
          return "/massagers/wave/wood";
        case 6:
          return "/massagers/wave/plastic";
      }
    })();

    setSelectedMassager(massager || null);
    setImageSource(imageSource || null);
   }
  }, [name]);

  return (
   <MassagerContext.Provider value={setSelectedMassager}>
     <Container fixed component="section" className="massager-section" id="massager">
      {selectedMassager ? (
       <>
         <Grid className="massager-section__title-material">
           <Typography className="massager-section__title" component="h3" variant="h4" color="primary">
            {t("massager")} {t(selectedMassager.title)}
           </Typography>
           <Typography className="massager-section__material" component="div" variant="h4" color="textPrimary">
             ({t(selectedMassager.material.toLowerCase()).toLowerCase()})
           </Typography>
         </Grid>
         <Grid container spacing={4} className="massager-section__img-specs">
           <Grid item md={6} className="massager-section__img">
             <img
               srcSet={`
                   ${imageSource}-xs.webp 300w,
                   ${imageSource}-s.webp 500w,
                   ${imageSource}-m.webp 700w,
                   ${imageSource}-l.webp 880w,
                   ${imageSource}-xl.webp 1024w
               `}
               src={`/massagers/${imageSource}-xl.webp`}
               alt={t(selectedMassager.title) as string}
             />
           </Grid>
           <Grid item md={6}>
            <Typography className="massager-section__price" component="p" color="textPrimary">
              {t("price")}
            </Typography>
            <Typography className="massager-section__price" component="p" variant="h3" color="primary">
              {selectedMassager.actualPrice} грн
            </Typography>
            <Box display="flex" className="massager-section__buttons">
               <CustomButton
                 variant="contained"
                 color="secondary"
                 text={t("buy_button") as string}
                 onClick={handleBuyMassager}
               />
            </Box>
            <Typography className="massager-section__specs-name" component="p" variant="h5" color="primary">
              {t("specs")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3" color="textPrimary">
                {t("massage_area")}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3">
                {t(selectedMassager.massage_area)}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3" color="textPrimary">
                {t("control_type")}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3">
                {t(selectedMassager.control_type)}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3" color="textPrimary">
                {t("material")}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3">
                {t(selectedMassager.material)}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3" color="textPrimary">
                {t("condition")}
               </Typography>
              </Grid>
              <Grid item xs={6}>
               <Typography className="massager-section__specs" component="p" variant="h3">
                {t(selectedMassager.condition)}
               </Typography>
              </Grid>
            </Grid>
           </Grid>
         </Grid>
         <Box mt={5}>
          <Tabs
            className="massager-section__tabs"
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
          >
            <Tab className="massager-section__tab-name" label={t("description")} />
            <Tab className="massager-section__tab-name" label={t("features")} />
          </Tabs>
          {activeTab === 0 && (
            <Box p={3}>
              <Typography className="massager-section__description" component="p" variant="h3">
                {t(selectedMassager.description)}
              </Typography>
            </Box>
          )}
          {activeTab === 1 && (
            <Box p={3}>
             <ul>
              <li key={selectedMassager.features.feature_1.id} className="massager-section__features">
               <Typography variant="h5" component="h3" color="primary">
                {t(selectedMassager.features.feature_1.name)}
               </Typography>
               {selectedMassager.features.feature_1.features.map((text) => (
                <li
                 key={selectedMassager.features.feature_1.id + selectedMassager.features.feature_1.features.indexOf(text) + 1}>
                 <Typography variant="body1" component="p" color="textPrimary" className="subtext">
                  {t(text)}
                 </Typography>
                </li>
               ))}
              </li>
              <li key={selectedMassager.features.feature_2.id} className="massager-section__features">
               <Typography variant="h5" component="h3" color="primary">
                {t(selectedMassager.features.feature_2.name)}
               </Typography>
               {selectedMassager.features.feature_2.features.map((text) => (
                <li
                 key={selectedMassager.features.feature_2.id + selectedMassager.features.feature_2.features.indexOf(text) + 1}>
                 <Typography variant="body1" component="p" color="textPrimary">
                  {t(text)}
                 </Typography>
                </li>
               ))}
              </li>
              <li key={selectedMassager.features.feature_3.id} className="massager-section__features">
               <Typography variant="h5" component="h3" color="primary">
                {t(selectedMassager.features.feature_3.name)}
               </Typography>
               {selectedMassager.features.feature_3.features.map((text) => (
                <li
                 key={selectedMassager.features.feature_3.id + selectedMassager.features.feature_3.features.indexOf(text) + 1}>
                 <Typography variant="body1" component="p" color="textPrimary">
                  {t(text)}
                 </Typography>
                </li>
               ))}
              </li>
             </ul>
            </Box>
          )}
         </Box>
       </>
      ) : (
       <Typography variant="body1">
        Товар не найдено
       </Typography>
      )}
     </Container>

     {(showModal && selectedMassager) ? ReactDOM.createPortal(
             <Purchase closeModal={closeModal} good={t(selectedMassager.title) + " (" + t(selectedMassager.material) + ")"} />,
             node
           )
         : null}
   </MassagerContext.Provider>
  );
}
