import React, { useEffect } from "react";

import { Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

import logo from "../../assets/images/bodymax-logo.webp";
import "./massager-page.scss";

import {Footer} from "../../components/Footer/Footer";
import {MassagerSection} from "../../components/MassagerSection/MassagerSection";

export const MassagerPage: React.FC = () => {
  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <>
      <Container fixed>
        <Box className="massager-navbar" id="navbar">
          <Link to="/">
            <img src={logo} alt="Bodymax" className="massager-navbar__logo"/>
          </Link>
        </Box>
      </Container>
      <MassagerSection />
      <Footer />
    </>
  );
};