import { t } from "i18next";


export const validateInputs = (checkedStr: string, type: string) => {
  switch (type) {
    case "name":
      if (checkedStr.length === 0) {
        return t("errors.empty_field");
      // } else if (!nameTestRegexp.test(checkedStr)) {
      //   return t("errors.invalid_name");
      } else {
        return "";
      }
    case "phone":
      if (checkedStr.length === 0) {
        return t("errors.empty_field");
      } else {
        return "";
      }
    default:
      return "";
  }
};
