import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { HeaderHero } from "../HeaderHero/HeaderHero";
import "./header.scss";

export const Header = () => {
  return (
    <>
      <header className="header">
        <Navbar withLogo />
      </header>
      <HeaderHero />
    </>
  );
};
