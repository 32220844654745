import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { init } from "emailjs-com";
import { animateScroll } from "react-scroll";
import AOS from "aos";

import { ToTopButton } from "../atoms/ToTopButton/ToTopButton";

import App from "../App";
import { Privacy } from "../components/Privacy/Privacy";
import { CoursesPage } from "../pages/CoursesPage/CoursesPage";
import { MassagersPage } from "../pages/MassagersPage/MassagersPage";

import "react-toastify/dist/ReactToastify.css";
import { FeedbackAlert } from "../components/FeedbackAlert/FeedbackAlert";
import {MassagerSection} from "../components/MassagerSection/MassagerSection";
import {MassagerPage} from "../pages/MassagerPage/MassagerPage";

declare global {
  interface Window {
    LiqPayCheckoutCallback: any;
  }
}
declare const LiqPayCheckout: any;

export const AppContainer: React.FC = () => {
  const [toTopButton, setToTopButton] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 1000) {
      setToTopButton(true);
    } else {
      setToTopButton(false);
    }
  };
  const handleToTopButtonClick = () => animateScroll.scrollToTop();

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    AOS.init({
      once: true,
    });
    AOS.refresh();
    init("user_MIMf1LEHeLqfXpdGpc8Ho");

    window.LiqPayCheckoutCallback = function () {
      return LiqPayCheckout;
    };

    const feedbackModalTimeoutId = setTimeout(
      () => setShowFeedbackModal(true),
      10000
    );

    return () => {
      document.removeEventListener("scroll", handleScroll);
      clearTimeout(feedbackModalTimeoutId);
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/privacy" component={Privacy} />
        <Route path="/courses" component={CoursesPage} />
        <Route exact path="/massagers" component={MassagersPage} />
        <Route path="/massagers/:name" component={MassagerPage} />
        <Route path="/" component={App} exact />
      </Switch>
      {toTopButton && (
        <ToTopButton handleToTopButtonClick={handleToTopButtonClick} />
      )}
      {showFeedbackModal && <FeedbackAlert />}
      <ToastContainer />
    </BrowserRouter>
  );
};
