// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.massagers {
  margin-bottom: 150px;
}
.massagers__title.MuiTypography-root {
  margin-bottom: 30px;
}
@media screen and (max-width: 1279px) {
  .massagers__title.MuiTypography-root {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 959px) {
  .massagers__title.MuiTypography-root {
    font-size: 1.6rem;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 599px) {
  .massagers__title.MuiTypography-root {
    font-size: 1.1rem;
  }
}
.massagers__description.MuiTypography-root {
  font-weight: normal;
  text-align: justify;
  margin-bottom: 40px;
  font-size: 1.25rem;
}
@media screen and (max-width: 599px) {
  .massagers__description.MuiTypography-root {
    font-size: 1rem;
  }
}
.massagers__description.MuiTypography-root span {
  font-weight: normal;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/MassagersSection/massagers-section.scss","webpack://./src/assets/sass/media.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;AAGE;EACE,mBAAA;AADJ;ACJE;EDIA;IAII,iBAAA;EAAJ;AACF;ACHE;EDFA;IAOI,iBAAA;IACA,mBAAA;EAEJ;AACF;ACHE;EDRA;IAWI,iBAAA;EAIJ;AACF;AADE;EACE,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAGJ;ACdE;EDOA;IAOI,eAAA;EAIJ;AACF;AAFI;EACE,mBAAA;EACA,cAAA;AAIN","sourcesContent":["@import \"../../assets/sass/media.scss\";\r\n\r\n.massagers {\r\n  margin-bottom: 150px;\r\n\r\n  &__title.MuiTypography-root {\r\n    margin-bottom: 30px;\r\n\r\n    @include laptop {\r\n      font-size: 1.9rem;\r\n    }\r\n    @include tablet {\r\n      font-size: 1.6rem;\r\n      margin-bottom: 40px;\r\n    }\r\n    @include mobile {\r\n      font-size: 1.1rem;\r\n    }\r\n  }\r\n\r\n  &__description.MuiTypography-root {\r\n    font-weight: normal;\r\n    text-align: justify;\r\n    margin-bottom: 40px;\r\n    font-size: 1.25rem;\r\n\r\n    @include mobile {\r\n      font-size: 1rem;\r\n    }\r\n\r\n    span {\r\n      font-weight: normal;\r\n      color: #000000;\r\n    }\r\n  }\r\n\r\n}","@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
