import React, { useEffect } from "react";

import { Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

import logo from "../../assets/images/bodymax-logo.webp";
import "./courses-page.scss";

import {CoursesSection} from "../../components/CoursesSection/CoursesSection";
import {Feedback} from "../../components/Feedback/Feedback";
import {Footer} from "../../components/Footer/Footer";

export const CoursesPage: React.FC = () => {
  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <>
      <Container fixed>
        <Box className="courses-navbar" id="navbar">
          <Link to="/">
            <img src={logo} alt="Bodymax" className="courses-navbar__logo"/>
          </Link>
        </Box>
      </Container>
      <CoursesSection />
      <Feedback />
      <Footer />
    </>
  );
};
