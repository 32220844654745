import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Grid, Link, Typography } from "@material-ui/core";

import "./contacts-section.scss";

export const ContactsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box component="section" className="contacts" id="contacts">
      <Container fixed>
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="contacts__title"
        >
          {t("contacts_section_title")}
        </Typography>
      </Container>
      <Box className="contacts__map-wrapper">
        <Container fixed>
          <Grid container alignItems="center" className="card-container">
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              className="contacts__card"
              data-aos="zoom-out-down"
              data-aos-delay="200"
            >
              <Typography component="h4" color="primary">
                {t("contacts.address.title")}:
              </Typography>
              <Typography
                component={Link}
                href="https://goo.gl/maps/tysJSDs7K8jyvcjK7"
                className="address"
                target="_blank"
                color="textPrimary"
                variant="body1"
              >
                {t("contacts.address.text")}
              </Typography>
              <Typography component="h4" color="primary">
                Номер телефона:
              </Typography>
              <Typography
                component="a"
                href="tel:+380509965325"
                color="textPrimary"
                variant="body1"
                className="tel"
              >
                +38 (050) 996-53-25,
              </Typography>
              <Typography component="h4" color="primary">
                {t("contacts.schedule.title")}:
              </Typography>
              <Typography
                component="p"
                color="textPrimary"
                variant="body1"
                className="schedule"
              >
                {t("contacts.schedule.item_1")} <br />
                {t("contacts.schedule.item_2")}
                {/*{t("contacts.schedule.item_3")}*/}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
