import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { animateScroll } from 'react-scroll';
// import logo from '../../assets/images/bodymax-logo.svg';
import './privacy.scss';
import {t} from "i18next";

const items = [
	{
		id: 1,
		title: t("privacy.first.title"),
		text: "",
		subtext: [
			t("privacy.first.subtext.first"),
			t("privacy.first.subtext.second"),
			t("privacy.first.subtext.third"),
			t("privacy.first.subtext.fourth"),
		]
	},
	{
		id: 2,
		title: t("privacy.second.title"),
		text: "",
		subtext: [
			t("privacy.second.subtext.first"),
			t("privacy.second.subtext.second"),
			t("privacy.second.subtext.third"),
			t("privacy.second.subtext.fourth"),
			t("privacy.second.subtext.fifth"),
			t("privacy.second.subtext.sixth"),
			]
	},
	{
		id: 3,
		title: t("privacy.third.title"),
		text: "",
		subtext: [
			t("privacy.third.subtext.first"),
			t("privacy.third.subtext.second"),
		]
	},
	{
		id: 4,
		title: t("privacy.fourth.title"),
		text: "",
		subtext: [
		 t("privacy.fourth.subtext.first"),
			t("privacy.fourth.subtext.second"),
		]
	},
	{
		id: 5,
		title: t("privacy.fifth.title"),
		text: "",
		subtext: [
		 t("privacy.fifth.subtext.first"),
			t("privacy.fifth.subtext.second"),
		]
	},
	{
		id: 6,
		title: t("privacy.sixth.title"),
		text: "",
		subtext: [
		 t("privacy.sixth.subtext.first"),
			t("privacy.sixth.subtext.second"),
			t("privacy.sixth.subtext.third"),
			t("privacy.sixth.subtext.fourth"),
			t("privacy.sixth.subtext.fifth"),
			t("privacy.sixth.subtext.sixth"),
			t("privacy.sixth.subtext.seventh"),
		]
	},
	{
		id: 7,
		title: t("privacy.seventh.title"),
		text: "",
		subtext: [
		 t("privacy.seventh.subtext.first"),
			t("privacy.seventh.subtext.second"),
		]
	},
	{
		id: 8,
		title: t("privacy.eighth.title"),
		text: "",
		subtext: [
		 t("privacy.eighth.subtext.first"),
			t("privacy.eighth.subtext.second"),
			t("privacy.eighth.subtext.third"),
		]
	},
	{
		id: 9,
		title: t("privacy.ninth.title"),
		text: "",
		subtext: [
		 t("privacy.ninth.subtext.first"),
			t("privacy.ninth.subtext.second"),
			t("privacy.ninth.subtext.third"),
			t("privacy.ninth.subtext.fourth"),
			t("privacy.ninth.subtext.fifth"),
		]
	},
	{
		id: 10,
		title: t("privacy.tenth.title"),
		text: "",
		subtext: [
		 t("privacy.tenth.subtext.first"),
			t("privacy.tenth.subtext.second"),
			t("privacy.tenth.subtext.third"),
			t("privacy.tenth.subtext.fourth"),
			t("privacy.tenth.subtext.fifth"),
		]
	},
];

export const Privacy: React.FC = () => {
	useEffect(() => {
		animateScroll.scrollToTop();
	}, []);

	return (
		<>
			<Container fixed>
				<Box className="privacy-navbar" id="navbar">
					<Link to="/">
						 {/*<img src={logo} alt="Bodymax" className="privacy-navbar__logo"/>*/}
					</Link>
				</Box>
				<Box className="privacy-content">
					<Typography component="h1" variant="h2" color="primary">
						{t("navbar.privacy")}
					</Typography>
					<Typography variant="body1" component="p" color="textPrimary">
						{t("privacy.intro")}
					</Typography>
					<ul>
						{items.map(({ id, title, text, subtext }) => (
							<li key={id}>
								<Typography variant="h5" component="h3" color="primary">
									{title}
								</Typography>
								{subtext.map((text, idx) => (
									<li key={id + idx}>
										<Typography variant="body1" component="p" color="textPrimary">
											{text}
										</Typography>
									</li>
								))}
							</li>
						))}
					</ul>
				</Box>
			</Container>
			<Footer />
		</>
	)
}
